<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <!-- isMobile() -->
    <!-- <div
      class="content-wrapper mt-100"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    > -->
    <div
      :class=" isMobile() ? 'content-wrapper mt-75' : 'content-wrapper mt-20'"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    AppBreadcrumb,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>

<style>
.mt-20 {
  margin-top: 20px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

/* MARCH-9 */

.header-navbar-shadow {
    padding-top: 8.5rem !important;
    /* background: linear-gradient(180deg, rgb(248 248 248) 44%, rgb(248 248 248) 73%, rgba(255, 255, 255, 0)) !important; */
    background: linear-gradient(180deg, rgb(245 242 236) 44%, rgb(244 241 235) 73%, rgb(244 241 235 / 19%)) !important;
    /* background: linear-gradient(180deg, rgb(255 255 255) 44%, rgb(255 255 255) 73%, rgb(255 255 255 / 19%)) !important; */
    left: 0;
}

</style>
