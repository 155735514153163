<template>

  <div>

    <!-- DESKTOP VIEW -->
    <b-nav-item-dropdown
      v-if="!isMobile()"
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content class="ajay">
        <div class="d-sm-flex d-none user-nav mr-1">
          <p class="user-name font-weight-bolder mb-0 text-black">
            {{ user_full_name }}
            <!-- AJAY CHAUHAN {{ userData.fullname || userData.username }} -->
          </p>
          <span class="user-status text-black">
            <!-- {{ userData.role }} -->
            {{ user_type }}
          </span>
        </div>
        <b-avatar
          size="40"
          :src="`https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/13-small.d796bffd.png`"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon icon="UserIcon" size="22" />
        </b-avatar>
      </template>

      <b-dropdown-item
        :to="{ name: 'account-setting' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
        <span>Settings</span>
      </b-dropdown-item>
      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Logout</span>
      </b-dropdown-item>

    </b-nav-item-dropdown>




    <!-- MOBILE VIEW -->
    <b-nav-item-dropdown
      v-else
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ user_full_name }}
            <!-- AJAY CHAUHAN {{ userData.fullname || userData.username }} -->
          </p>
          <span class="user-status">
            <!-- {{ userData.role }} -->
            {{ user_type }}
          </span>
        </div>
        <b-avatar
          size="40"
          :src="`https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/13-small.d796bffd.png`"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon icon="UserIcon" size="22" />
        </b-avatar>
      </template>

      <b-dropdown-item
        :to="{ name: 'account-setting' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
        <span>Settings</span>
      </b-dropdown-item>
      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Logout</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>


  </div>

</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import storageService from "@/apiServices/storageService";
import store from '@/store';

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      user_full_name: "",
      user_type: "",
      userData: {}  //JSON.parse(localStorage.getItem("userInfo")),
    };
  },
  beforeMount() {
    this.loadUserAction();
    this.userData = store.getters["user/getUserDetails"];
  },
  methods: {
    loadUserAction() {
      const profileData = storageService.UserData.getUserProfile();
      let user = JSON.parse(profileData);
      this.user_full_name = user.user_firstname;
      if(user.user_role_id == 1){
        this.user_type = "SELLER";
      }else{
        this.user_type = "ADMIN";
      }
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },

    logout() {

      // Remove userData from localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      this.$router.push({ name: "onboarding" });
    },
  },
};
</script>


<style>
.mt-m-10{
  margin-top: -60px !important;
}

.d-sm-flex {
    display: block !important;
}
</style>