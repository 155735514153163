<template>
  <div>

    <!-- DESKTOP VIEW -->
    <div v-if="!isMobile()" class="d-flex align-items-center justify-content-end">

      <ul class="nav navbar-nav d-xl-none mr-auto">
        <li class="nav-item">
          <b-link class="nav-link" @click="toggleVerticalMenuActive">
            <feather-icon icon="MenuIcon" size="21" />
          </b-link>
        </li>
      </ul>

      <b-navbar-nav class="nav align-items-center top-right">
        <user-dropdown />
      </b-navbar-nav>
    </div>


    <!-- MOBILE VIEW -->
    <div v-else class="navbar-container d-flex content align-items-center">
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link class="nav-link" @click="toggleVerticalMenuActive">
            <feather-icon icon="MenuIcon" size="21" />
          </b-link>
        </li>
      </ul>

      <!-- Left Col -->
      <div
        class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
      >
        <bookmarks />
      </div>

      <b-navbar-nav class="nav align-items-center ml-auto">
        <locale />
        <dark-Toggler class="d-none d-lg-block" />
        <user-dropdown />
      </b-navbar-nav>
    </div>
    
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";

export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    //Locale,
    SearchBar,
    DarkToggler,
    //CartDropdown,
    //NotificationDropdown,
    "user-dropdown": UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
.mt-15 {
  margin-top: 15px !important;
  float: right !important;
}
</style>
