export default [

  {
    title: "Add New Item",
    icon: "FilePlusIcon",
    route: "sell_refurbish",
    user_types: [1]
  },

  {
    title: "Dashboard",
    route: "analytics",
    icon: "ActivityIcon",
    module_id: "analytics",
    user_types: [1]
  },
  {
    title: "Items ",
    route: "product_list",
    icon: "PackageIcon",
    module_id: "products",
    user_types: [1]
  },
  {
    title: "Notifications",
    route: "notifications",
    icon: "MessageSquareIcon",
    module_id: "notifications",
    user_types: [1]
  },
  {
    // title: "Help & Support",
    title: "FAQ's",
    route: "help-support",
    icon: "HelpCircleIcon",
    module_id: "help-support",
    user_types: [1]
  },
  {
    title: "Account Settings",
    route: "account-setting",
    icon: "SettingsIcon",
    module_id: "account-setting",
    user_types: [1]
  },
  // {
  //   title: "Invoices",
  //   icon: "FileTextIcon",
  //   route: "invoice-list",
  //   module_id: "invoice-list",
  //   user_types: [1]
  // },

  {
    title: "Back to Revivify.com",
    under_line: true,
    href: "https://uat.revivify.com/",
    user_types: [1],
    target: "_self"
  },

  // {
  //   title: "Earn & Credit",
  //   icon: "DollarSignIcon",
  //   route: "earn-credit",
  //   module_id: "earn-credit",
  //   user_types: [1]
  // },

  // ADMIN MENUS //

  {
    title: "Dashboard",
    route: "dashboard",
    icon: "ActivityIcon",
    module_id: "admin-dashboard",
    user_types: [2]
  },
  {
    title: "Masters",
    icon: "PackageIcon",
    module_id: "admin-masters",
    user_types: [2],
    children: [
      {
        title: "Services",
        route: { name: "service-list" }
      },
      {
        title: "Item Type",
        route: { name: "ItemType-list", params: { tag_type_name: "ItemType" } }
      },
      {
        title: "Designer",
        route: { name: "Designer-list", params: { tag_type_name: "Designer" } }
      },
      {
        title: "Condition",
        route: { name: "Condition-list", params: { tag_type_name: "Condition" } }
      },
      {
        title: "Material",
        route: { name: "Material-list", params: { tag_type_name: "Material" } }
      },
      {
        title: "Indian Bottom Waist",
        route: { name: "IndianBottomWaist-list", params: { tag_type_name: "IndianBottomWaist" } }
      },
      {
        title: "Indian Bust",
        route: { name: "IndianBust-list", params: { tag_type_name: "IndianBust" } }
      },
      {
        title: "Indian Top Hip",
        route: { name: "IndianTopHip-list", params: { tag_type_name: "IndianTopHip" } }
      },
      {
        title: "Western Bottom",
        route: { name: "WesternBottom-list", params: { tag_type_name: "WesternBottom" } }
      },
      {
        title: "Western Top",
        route: { name: "WesternTop-list", params: { tag_type_name: "WesternTop" } }
      },
      {
        title: "Currency",
        route: { name: "currency-list", }
      },


    ]
  },
  // {
  //   title: "Services",
  //   icon: "PackageIcon",
  //   route: "service-list",
  //   module_id: "admin-services",
  //   user_types: [2]
  // },
  {
    title: "Items",
    icon: "BoxIcon",
    route: "admin-items",
    module_id: "admin-items",
    user_types: [2]
  },
  {
    title: "Users Management",
    icon: "UsersIcon",
    route: "admin-user-management",
    module_id: "admin-user-management",
    user_types: [2]
  },
  // {
  //   title: "Invoice",
  //   icon: "FileTextIcon",
  //   route: "admin-invoice",
  //   module_id: "admin-invoice",
  //   user_types: [2]
  // },
  {
    title: "Account Settings",
    icon: "SettingsIcon",
    route: "admin-account-settings",
    module_id: "admin-account-settings",
    user_types: [2]
  },
  {
    title: "Account Notifications",
    icon: "MessageSquareIcon",
    route: "admin-notifications",
    module_id: "admin-notifications",
    user_types: [2]
  },


];
