<template>
  <div class="main-view">




    <!-- DESKTOP VIEW -->
    <div
      v-if="!isMobile()"
      class="vertical-layout h-100"
      :class="[layoutClasses]"
      :data-col="isNavMenuHidden ? '1-column' : null"
    >
    
      <div>
        <div align="center" class="header-navbar align-items-center" :class="[navbarTypeClass]">
          <b-row>
            <b-col md="12" class="mainLogo">
              <img
                  class="revivify-logo"
                  height="35px"
                  :src="require('@/assets/images/logo/new_logo.png')"
                  alt="Revivify Logo"
              />
            </b-col>
            <b-col cols="12">
              <div class="float-right-user-dd">
                  <b-navbar
                    :toggleable="false"
                    :variant="navbarBackgroundColor"
                    class="header-navbar navbar navbar-shadow align-items-center make-right"
                    :class="[navbarTypeClass]"
                  >
                    <slot
                      name="navbar"
                      :toggleVerticalMenuActive="toggleVerticalMenuActive"
                      :navbarBackgroundColor="navbarBackgroundColor"
                      :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
                    > 
                      <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
                    </slot>
                  </b-navbar>
                </div>
            </b-col>
            
          </b-row>
        </div>
      </div>


      <!-- Vertical Nav Menu -->
      <vertical-nav-menu
        v-if="!isNavMenuHidden"
        :is-vertical-menu-active="isVerticalMenuActive"
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
      >
        <template #header="slotProps">
          <slot
            name="vertical-menu-header"
            v-bind="slotProps"
          />
        </template>
      </vertical-nav-menu>
      <!-- /Vertical Nav Menu -->

      <!-- Vertical Nav Menu Overlay -->
      <div
        class="sidenav-overlay"
        :class="overlayClasses"
        @click="isVerticalMenuActive = false"
      />
      <!-- /Vertical Nav Menu Overlay -->

      <!-- CONTENT TYPE: Left -->
      <transition
        :name="routerTransition"
        mode="out-in"
      >
        <component
          :is="layoutContentRenderer"
          :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
        >
          <template
            v-for="(index, name) in $scopedSlots"
            v-slot:[name]="data"
          >
            <slot
              :name="name"
              v-bind="data"
            />
          </template>
        </component>
      </transition>
      <!--/ Content -->

      <!-- Footer -->
      <footer
        class="footer footer-light"
        :class="[footerTypeClass]"
      >
        <slot name="footer">
          <app-footer />
        </slot>
      </footer>
      <!-- /Footer -->

      <slot name="customizer" />
    </div>



    
    <!-- MOBILE VIEW -->
    <div
      v-else
      class="vertical-layout h-100"
      :class="[layoutClasses]"
      :data-col="isNavMenuHidden ? '1-column' : null"
    > 

      <!-- <div class="mobile-logo-with-header"> -->
      <div align="center" class="header-navbar align-items-center" :class="[navbarTypeClass]">
        <b-row>
          <b-col md="12">

              <img
                class="revivify-logo"
                height="35px"
                :src="require('@/assets/images/logo/new_logo.png')"
                alt="Revivify Logo"
              /> 

              <hr>

              <!-- Navbar -->
              <b-navbar
                :toggleable="false"
                :variant="navbarBackgroundColor"
                class="header-navbar navbar navbar-shadow-mob align-items-right"
                :class="[navbarTypeClass]"
              >
                
                <slot
                  name="navbar"
                  :toggleVerticalMenuActive="toggleVerticalMenuActive"
                  :navbarBackgroundColor="navbarBackgroundColor"
                  :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow-mob align-items-right']"
                >
                  <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
                </slot>
              </b-navbar>
              <!--/ Navbar -->

          </b-col>
        </b-row>
      </div>

      <!-- Vertical Nav Menu -->
      <vertical-nav-menu
        v-if="!isNavMenuHidden"
        :is-vertical-menu-active="isVerticalMenuActive"
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
      >
        <template #header="slotProps">
          <slot
            name="vertical-menu-header"
            v-bind="slotProps"
          />
        </template>
      </vertical-nav-menu>
      <!-- /Vertical Nav Menu -->

      <!-- Vertical Nav Menu Overlay -->
      <div
        class="sidenav-overlay"
        :class="overlayClasses"
        @click="isVerticalMenuActive = false"
      />
      <!-- /Vertical Nav Menu Overlay -->

      <!-- Content -->

      <!-- CONTENT TYPE: Left -->
      <transition
        :name="routerTransition"
        mode="out-in"
      >
        <component
          :is="layoutContentRenderer"
          :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
        >
          <template
            v-for="(index, name) in $scopedSlots"
            v-slot:[name]="data"
          >
            <slot
              :name="name"
              v-bind="data"
            />
          </template>
        </component>
      </transition>
      <!--/ Content -->

      <!-- Footer -->
      <footer
        class="footer footer-light"
        :class="[footerTypeClass]"
      >
        <slot name="footer">
          <app-footer />
        </slot>
      </footer>
      <!-- /Footer -->

      <slot name="customizer" />
    </div>

  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

import { onUnmounted } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'

export default {
  components: {

    BRow,
    BCol,
    BButton,

    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
  },
  mixins: [mixinVerticalLayout],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
    }
  },
  data(){
    return {
      wp_menu: [
      {
        "name":"NEW IN",
        "link":"https://uat.revivify.com/"
      },
      {
        "name":"DESIGNERS",
        "link":"https://uat.revivify.com/"
      },
      {
        "name":"WOMEN",
        "link":"https://uat.revivify.com/"
      },
      {
        "name":"ACCESSORIES",
        "link":"https://uat.revivify.com/"
      },
      {
        "name":"MEN",
        "link":"https://uat.revivify.com/"
      },
      {
        "name":"KIDS",
        "link":"https://uat.revivify.com/"
      },
      {
        "name":"JOURNAL",
        "link":"https://uat.revivify.com/"
      }
      ] 
    }
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>

<style>

.revivify-logo{
  margin-top: 5px !important;
}

[dir] .header-navbar.navbar-shadow{
  box-shadow: none !important;
}

[dir] .header-navbar.navbar-shadow-mob{
  box-shadow: none !important;
  background: white !important;
}

[dir] .navbar-light{
  background: none !important;
}

.mt-150{
  margin-top: 150px !important;
}

.wp-menu-li{
  padding: 20px 12px;
  font-size: large;
  font-weight: 1000;
  color: black;
}

.wp-menu-li > a{
  color: #111111 !important;
}

.align-items-right{
  display: block !important;
  float: right;
}

.make-right{
  display: contents !important;
}

hr{
  margin: 10px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
}

</style>

<style lang="scss">
  @import "~@core/scss/base/themes/bordered-layout.scss";
  .mainLogo{
    position: absolute;
  }
</style>
